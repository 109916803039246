import React from 'react'
import styles from './layout.module.css'
import { Link, useStaticQuery, graphql } from 'gatsby'
import logo from '../images/devpedal-logo.png'

export default ({ location, children }) => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const menu = [
    {
      id: 0,
      title: 'Home',
      link: '/'
    },
    {
      id: 1,
      title: 'Services',
      link: '/services'
    },
    {
      id: 2,
      title: 'Contact',
      link: '/contact'
    }
  ]

  return (
    <div>
      <header className={styles.header}>
        <h3>
          <Link to="/">
            <img src={logo} alt={data.site.siteMetadata.title} />
          </Link>
        </h3>
        <ul className={styles.menu}>
          {menu.map(m => {
            return (
              <li
                key={m.id}
                className={
                  m.link === (location ? location.pathname : 'undefined')
                    ? styles.activeMenu
                    : ''
                }
              >
                <Link to={m.link}>{m.title}</Link>
              </li>
            )
          })}
        </ul>
      </header>
      <div className={styles.body}>{children}</div>
      <footer className={styles.footer}>
        <span>All Rights Reserved &copy; 2020 | devpedal.com</span>
      </footer>
    </div>
  )
}
